<template>
  <v-container fluid>
    <div class="d-flex mb-3" v-if="all">
      <v-alert type="info" class="ma-0" dense text> Eventos pasados </v-alert>
    </div>

    <div class="d-flex mb-2">
      <v-btn small color="primary" @click.stop="getEventos">
        <v-icon left small>mdi-refresh</v-icon>
        Recargar</v-btn
      >
    </div>

    <v-card>
      <v-tabs v-model="tipo">
        <v-tab href="#">TODOS</v-tab>
        <v-tab
          v-for="tipo in tipos"
          :key="tipo.idTipoEvento"
          :href="`#${tipo.idTipoEvento}`"
          >{{ tipo.tipo }}</v-tab
        >
      </v-tabs>

      <v-data-table
        fixed-header
        :items="eventos.filter(e => e.bNeto > 0)"
        @current-items="eventosExp = $event"
        :headers="eventosHeaders"
        class="fixed-checkbox"
        item-key="idEvento"
        :single-select="false"
        show-select
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
            itemsPerPageOptions: [10, 25, 50, -1],
        }"
        checkbox-color="secondary"
        >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="eventos"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.nombreEspacio="{ item }">
          <v-chip
            class="v-chip--active"
            outlined
            label
            v-if="item.nombreEspacio"
          >
            <v-icon left>mdi-map-marker</v-icon>
            {{ item.nombreEspacio }}
          </v-chip>
        </template>
        <template v-slot:item.tipoEvento="{ item }">
          <v-chip
            class="v-chip--active font-weight-bold"
            outlined
            label
            :color="item.colorTipoEvento"
          >
            <v-icon left>{{ item.iconTipoEvento }}</v-icon>
            {{ item.tipoEvento }}
          </v-chip>
        </template>
        <template v-slot:item.nombreSalon="{ item }">
          <div v-if="item.nombreSalon" class="d-flex" style="gap: 3px">
            <v-chip
              class="v-chip--active"
              outlined
              label
              v-for="sal in item.nombreSalon.split(',')"
              :key="sal"
            >
              <v-icon left>mdi-map-marker-outline</v-icon>
              {{ sal }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.datos.fechaInicioEvento="{ item }">
          <v-chip>
            <v-icon left>mdi-calendar</v-icon>
            <span v-text="parseDate(item.datos.fechaInicioEvento)"></span>
          </v-chip>
        </template>

        <template v-slot:item.minuta="{ item }">
          <v-icon v-if="item.minuta" color="success">mdi-checkbox-outline</v-icon>
          <v-icon v-else color="error">mdi-checkbox-blank-outline</v-icon>
        </template>
        <template v-slot:item.rrhh="{ item }">
          <v-icon v-if="item.rrhh" color="success">mdi-checkbox-outline</v-icon>
          <v-icon v-else color="error">mdi-checkbox-blank-outline</v-icon>
        </template>
        <template v-slot:item.compras="{ item }">
          <v-icon v-if="item.compras" color="success">mdi-checkbox-outline</v-icon>
          <v-icon v-else color="error">mdi-checkbox-blank-outline</v-icon>
        </template>
        <template v-slot:item.otros="{ item }">
          <v-icon v-if="item.otros" color="success">mdi-checkbox-outline</v-icon>
          <v-icon v-else color="error">mdi-checkbox-blank-outline</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
        <v-btn color="primary" fab @click="exportar" large class="me-4">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn color="primary" fab v-if="$root.acceso('CREAR_EVENTO')" to="/eventos/nuevo" large>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>

    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
    >
      <v-toolbar dense>
        <v-toolbar-title>{{ detailsId }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="detailsId = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <detalles-evento :key="detailsId" :idEvento="detailsId"></detalles-evento>
    </v-dialog>
  </v-container>
</template>

<script>
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";
import { parseDate, perColumnFilter } from "@/utils";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: {
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inlineFilters: {
        idEvento: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      tipo: null,
      eventos: [],
      eventosExp: [],
      loading: false,
      contextMenuComp: null,
      detailsId: null,
      cambioEstado: null,
      tipos: [],
    };
  },
  computed: {
    eventosHeaders() {
      return [
        { text: "FECHA",value: "fechaInicio" },
        { text: "ESPACIO", value: "nombreEspacio", dataType: "select" },
        { text: "EVENTO", value: "tipoEvento", dataType: "select" },
        { text: "VENTAS", value: "totalVentas"},
        { text: "BENEFICIO", value: "bNeto" },
        { text: "MARGEN (%)", value: "mNeto" },
        { text: "ADULTOS", value: "nAdultos" },
        { text: "PM PERSONA", value: "pVtaPersona" },
        { text: "EQUIPO (%)", value: "ratioEquipo" },
        { text: "F&B (%)", value: "ratioFnB" },
        { text: "O.C.E (%)", value: "ratioOce" },
        { text: "ALQUILERES (%)", value: "ratioAlquilerComision" },
        { text: "CATERING (%)", value: "ratioCatering" },
        { text: "GG (%)", value: "ratioGeneral" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso)) &&
            (h.tab == null ||
              [h.tab].flat().includes(this.tipo * 1) ||
              !this.tipo)
        )
        .map((header) => ({
          class: "text-no-wrap sticky-header",
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  mounted() {
    if (!(!!(this.$store.getters.getTokenPayload.permisos & this.$store.getters.getPermisos['RESUMEN_RENTABILIDAD']))) this.$router.push({ path: "/" });
    this.getEventos();
  },
  methods: {
    exportar() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('RENTABILIDAD');
      sheet.columns = [
        { header: "Nº", key: "n", width: 3,},
        { header: "FECHA", key: "fecha", width: 20,},
        { header: "ESPACIO", key: "espacio", width: 20,},
        { header: "TIPO", key: "tipo", width: 10,},
        { header: "VENTAS", key: "ventas", width: 20,},
        { header: "BENEFICIO", key: "beneficio", width: 20,},
        { header: "MARGEN (%)", key: "margen", width: 20,},
        { header: "ADULTOS", key: "ad", width: 20,},
        { header: "PM PERSONA", key: "pm", width: 20,},
        { header: "EQUIPO (%)", key: "equipo", width: 20,},
        { header: "F&B(%)", key: "fnb", width: 20,},
        { header: "OCE (%)", key: "oce", width: 20,},
        { header: "ALQUILERES (%)", key: "alq", width: 20,},
        { header: "CATERING (%)", key: "cat", width: 20,},
        { header: "GG (%)", key: "gg", width: 20,}
      ];
      sheet.getRow(1).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(1).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(7).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(10).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(11).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(12).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(13).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(14).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(15).alignment = { horizontal: 'center', wrapText: false };
      sheet.getColumn(7).numFmt = "0.00%";
      sheet.getColumn(10).numFmt = "0.00%";
      sheet.getColumn(11).numFmt = "0.00%";
      sheet.getColumn(12).numFmt = "0.00%";
      sheet.getColumn(13).numFmt = "0.00%";
      sheet.getColumn(14).numFmt = "0.00%";
      sheet.getColumn(15).numFmt = "0.00%";
      for (let i = 0; i < this.eventosExp.length; i++) {
        sheet.addRow([(i + 1),
        this.eventosExp[i].fechaInicio,
        this.eventosExp[i].nombreEspacio,
        this.eventosExp[i].tipoEvento,
        Math.round(this.eventosExp[i].totalVentas),
        Math.round(this.eventosExp[i].bNeto),
        (parseFloat(this.eventosExp[i].mNeto) / 100),
        parseFloat(this.eventosExp[i].nAdultos),
        parseFloat(this.eventosExp[i].pVtaPersona),
        (parseFloat(this.eventosExp[i].ratioEquipo) / 100),
        (parseFloat(this.eventosExp[i].ratioFnB) / 100),
        (parseFloat(this.eventosExp[i].ratioOce) / 100),
        (parseFloat(this.eventosExp[i].ratioAlquilerComision) / 100),
        (parseFloat(this.eventosExp[i].ratioCatering) / 100),
        (parseFloat(this.eventosExp[i].ratioGeneral) / 100)])
      }
      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${"RENTABILIDAD - EXPORTADO EL " + (new Date().toLocaleDateString('default', { weekday: 'short' }).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
    },
    parseDate,
    async getEventos() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/financiero/rentabilidad`,
      });

      this.eventos = data.map((m) => {
        return m;
      });

      this.eventos = this.eventos.filter(e => e.datos != null)

      this.eventos.forEach(coste => {
        coste.fechaInicio = parseDate(coste.date).toUpperCase()
        coste.nombreEspacio = (coste.custom_key.split('_')[0]).split(' - ')[0] == "CATERING" ? (coste.custom_key.split('_')[0]).split(' - ')[1] : (coste.custom_key.split('_')[0]).split(' - ')[0] 
        coste.tipoEvento = (coste.custom_key.split('_')[2])
        coste.totalVentas = Math.round(+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)
        var totalCatering = coste.datos.costesCatering.reduce((acc, v) => (typeof acc === 'object') ? acc.precio += +v.precio : +acc + +v.precio, 0);
        var totalOce = 0
        totalOce += coste.datos.cuestionario.fijo.filter(n => n.aplica == true).reduce((acc, v) => acc + v.precio, 0);
        totalOce += coste.datos.cuestionario.variable.filter(n => n.aplica == true).reduce((acc, v) => acc + (v.precio * ((coste.nNinos || 0) + (coste.nAdultos || 0))), 0);
        coste.datos.otrosExtras.forEach(coste => {
          totalOce += +coste.precio;
        });
        totalOce += ((Math.round((+(coste.datos.minuta.ventasFnB * (1 / 100)) + Number.EPSILON) * 100) / 100));
        totalOce += (2.5 * (+coste.nAdultos + +coste.nNinos));
        totalOce += ((+coste.nAdultos + +coste.nNinos) * 0.4);
        coste.bNeto = Math.round((((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) - (+((+coste.datos.equipo.camareros + +coste.datos.equipo.otros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
                      +
                      +totalOce
                      +
                      +(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision)
                      +
                      +totalCatering
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100))))

        coste.mNeto = (((((((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) - (+((+coste.datos.equipo.camareros + +coste.datos.equipo.otros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
                      +
                      +totalOce
                      +
                      +(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision)
                      +
                      +totalCatering
                      +
                      +(Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100)))) / (((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))))) * 100).toFixed(2) + '%'
        coste.pVtaPersona = Math.round(coste.totalVentas / Math.max(coste.nAdultos || 1))
        coste.ratioEquipo = ((((((+coste.datos.equipo.camareros + +coste.datos.equipo.otros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.cocineros) +
                      +(Math.round(+(+(coste.datos.minuta.ventasFnB * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100))) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))) * 100).toFixed(2)) + '%'
        coste.ratioFnB = (((Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100).toFixed(2)) + '%'
        coste.ratioOce = (totalOce / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100).toFixed(2) + '%'
        coste.ratioAlquilerComision = ((+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100).toFixed(2) + '%'
        coste.ratioCatering = ((totalCatering / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))) * 100).toFixed(2) + '%'
        coste.ratioGeneral = ((Math.round(((+(+coste.datos.minuta.ventasFnB * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100) / ((+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) * 100).toFixed(2) + '%'
      });
      this.eventos = this.eventos.sort((a,b) => {
        var keyA = new Date(a.date);
        var keyB = new Date(b.date);
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0
      })
      this.eventosExp = this.eventos
      this.loading = false;
    },
  },
};
</script>
